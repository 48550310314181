import React, {useEffect} from 'react';
import {CDataParams, getReportParameters, ReportParameter} from '../dbApi';
import SelectComponent, {Option} from './Select';
import {Button, ButtonGroup, TextField} from '@mui/material';

// program types to be omitted from default selections
const unwantedProgramTypes = ['TEC'];

const createOption = (val: string): Option => ({label: val, value: val});

// project types to be selected by default
const projectType = [
  'SHADE','NEW', 'ORG', 'MOD', 'CTE', 'SEC', 'HVAC', 'DEMO', 'ATH', 'FIELD',
  'JUF', 'ENER', 'FNC', 'PA', 'REC', 'FFE', 'DOH', 'ROU', 'PPO', 'BSRR','SPE'
].map(createOption);

let now = (new Date()).getFullYear();
const awardYear: Option[] = [];
// include first five years pre-selected
for (let i = 0; i < 5; i++) {
  awardYear.push(createOption(String(now)));
  now++;
}
const initialState: ParameterOptions = {
  awardYear,
  projectType
};

const Parameters: React.FC<IParametersProps> = (props) => {
  const [search, setSearch] = React.useState<string>('');
  const [parameterOptions, setParameterOptions] = React.useState<ParameterOptions>({});
  const [selectedParameterOptions, setSelectedParameterOptions] = React.useState<ParameterOptions>(initialState);

  useEffect(() => {
    (async function () {
      await getParameters();
      await doSearch();
    })();
  }, []);

  /**
   * get report parameters, and fill state object with options
   */
  const getParameters = async () => {
    try {
      props.setRunning(true);
      const value = await getReportParameters();
      setParameterOptions(convertParams(value));
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      props.setRunning(false);
    }
  };

  /**
   * Convert Array of parameters into an object of parameterOptions
   * @param parameters
   */
  const convertParams = (parameters: ReportParameter[]) => {
    const SUFFIX = 'Options';
    const convertedParams: ParameterOptions = {};
    for (const value of parameters) {
      const {parameter, ...option} = value;
      const prop = `${parameter}${SUFFIX}`;
      convertedParams[prop] = convertedParams[prop] || [];
      convertedParams[prop].push(option);
    }
    convertedParams['programType'] = convertedParams[`programType${SUFFIX}`].filter(({value}) => !unwantedProgramTypes.includes(value));
    return convertedParams;
  };

  const createFilter = () => {
    const filters = processSearchObject(selectedParameterOptions);
    if (search && search.length) {
      filters.push(`contains(projSearch, '${search}')`);
    }
    if(!filters.length) return {};
    return {$filter: `(${filters.join(') and (')})`};
  };

  const processSearchObject = (params: ParameterOptions): string[] => {
    return Object.keys(params).filter((key) => Boolean(params[key].length)).map((key) => {
      return createSearch(params[key], key);
    });
  };

  const createSearch = (arr: Option[], prop: string) => arr.map(({value}: Option) => `${prop} eq '${value}'`).join(' or ');

  const handleSelect = (name: string, value: Option[]) => {
    // @ts-ignore
    const newState: ParameterOptions = {
      ...selectedParameterOptions,
      [name]: value || []
    };
    console.log(value);
    setSelectedParameterOptions(newState);
  };

  const handleSearchInput = (e: any) => {
    setSearch((e.target.value || '').toLowerCase());
  };
  const doSearch = (e?: any) => {
    if (e && e.preventDefault) e.preventDefault();
    props.run(createFilter());
  };
  return (
    <div className="parameters-container">
      <div className="item">
        <SelectComponent
          label="Construction Start Year"
          name="awardYear"
          onSelect={handleSelect}
          options={parameterOptions.awardYearOptions}
          value={selectedParameterOptions['awardYear']}
        />
      </div>
      <div className="item">
        <SelectComponent
          label="Program Type"
          name="programType"
          onSelect={handleSelect}
          options={parameterOptions.programTypeOptions}
          value={selectedParameterOptions['programType']}
        />
      </div>
      <div className="item">
        <SelectComponent
          label="Delivery Method"
          name="deliveryMethod"
          onSelect={handleSelect}
          options={parameterOptions.deliveryMethodOptions}
          value={selectedParameterOptions['deliveryMethod']}
        />
      </div>
      <div className="item">
        <SelectComponent
          label="PM"
          name="pm"
          onSelect={handleSelect}
          options={parameterOptions.pmOptions}
          value={selectedParameterOptions['pm']}
        />
      </div>
      <div className="item">
        <SelectComponent
          label="Project Type"
          name="projectType"
          onSelect={handleSelect}
          options={parameterOptions.projectTypeOptions}
          value={selectedParameterOptions['projectType']}
        />
      </div>
      <div className="item">
        <TextField
          label="Wildcard Search (Project ID/Title)"
          name="projectSearch"
          id="projectSearch"
          value={search}
          variant="outlined"
          onChange={handleSearchInput}
        />
      </div>
      <div className="item">
        <ButtonGroup variant="contained">
          <Button onClick={doSearch} disabled={props.running}>Run</Button>
          <Button onClick={props.toCSV} disabled={props.running}>Export to CSV</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Parameters;

interface IParametersProps {
  running: boolean;
  setRunning(isRunning: boolean): any;
  run(params: CDataParams): any;
  toCSV(): void;
}

interface ParameterOptions {
  [prop: string]: Option[];
}

export type ReportParams = {
  search: string;
  programType: Option[];
  awardYear: Option[];
  pm: Option[];
  deliveryMethod: Option[];
  projectType: Option[];
}