import Axios from 'axios';

const ajax = Axios.create({
  baseURL: 'https://pcm.sandi.net/apiserver/api.rsc/',
  headers: {'x-cdata-authtoken': process.env.REACT_APP_API_TOKEN}
});

export const getReportData = async (params?: CDataParams): Promise<ReportData[]> => {
 const {data} = await ajax.get('/rainbowReportData',{params});
 return data.value;
};

export const getReportParameters = async (): Promise<ReportParameter[]> => {
  const {data} = await ajax.get('/rainbowReportParameters');
  return data.value;
};

export type ReportParameter = {
  parameter: Parameter;
} & SelectOption;

export type Parameter = 'pm' | 'constructionStart' | 'deliveryMethod' | 'projectType' | 'programType';

export type SelectOption = {
  label: string;
  value: string;
}

export type CDataParams = {
  $filter?: string;
  $select?: string;
};

export type ReportData = {
  projectType: string;
  aeffFinish: string;
  bidFinish: string;
  projectName: string;
  dsaStart: string;
  type: string;
  dsaFinish: string;
  aeffStatus: string;
  bidStartStatus: string;
  dsaFinishStatus: string;
  constBdgt: number;
  bidCac: number;
  programType: string;
  awardStatus: string;
  pm: string;
  aeNTPFinish: string;
  aeffStart: string;
  awardFinish: string;
  bidStart: string;
  designPhase: string;
  fundingSource: string;
  dsaStartStatus: string;
  constCmmt: number;
  constCac: number;
  curEstPhase?: string;
  cellColor: string;
  ae: string;
  rowColor: string;
  aeNTPStatus: string;
  awardYear: string;
  fundedYear: string;
  projPhase: string;
}

export const getReportString: () => Promise<string> = async () => {
  const {data} = await ajax.get('/applicationReports',{
    params: {
      $filter: 'applicationName eq "rainbow-report" and templateName eq "rainbow-report" and  isActive eq "true"'
    }
  });
  return data.value[0].content;
};